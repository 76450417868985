import React from 'react'
import {
    FiActivity, FiCalendar,
    FiCompass,
    FiHelpCircle, FiUser,
} from 'react-icons/fi'

export const SET_MENU = "SET_MENU";


export default function navigation(state = [], action) {
    switch (action.type) {
        case SET_MENU: {
            return getMenuItems(action.rights)
        }
        default:
            return state
    }
}

const getMenuItems = (rights) => {
    const initialState = [
        {
            title: 'Možnosti',
            items: [{url: '/dashboard', icon: <FiCompass size={20}/>, title: 'Dashboard', items: []}]
        }
    ]
    if (rights?.admin?.events?.view) {
        initialState[0].items.push({url: '/events', icon: <FiCalendar size={20}/>, title: 'Události', items: []})
    }
    if (rights?.admin?.validation?.view) {
        initialState[0].items.push({
            url: '/validation', icon: <FiCalendar size={20}/>, title: 'Validace vstupenky', items: [],
            key: "validation"
        })
    }

    if (rights?.admin?.arenaEvents?.view) {
        initialState[0].items.push({
            url: '/arena-events', icon: <FiCalendar size={20}/>, title: 'Aréna události', items: [],
            key: "arenaEvents"
        })
    }
    if (rights?.admin?.arenaEntrances?.view) {
        initialState[0].items.push({
            url: '/arena-entrances', icon: <FiCalendar size={20}/>, title: 'Aréna průchody', items: [],
            key: "arenaEntrances"
        })
    }
    if (rights?.admin?.users?.view) {
        initialState[0].items.push({
            url: '/users', icon: <FiUser size={20}/>, title: 'Uživatelé', items: [],
            key: "users"
        })
    }

    if (rights?.admin?.vcb?.view) {
        initialState[0].items.push({
            url: '', icon: <FiUser size={20}/>, title: 'Výstaviště ČB', items: [
                {
                    url: '/vcb/actual', icon: <FiUser size={20}/>, title: 'Aktuální stav', items: [],
                    key: "vcb-actual"
                }
            ],
            key: "vcb"
        })
    }


    return initialState
}
