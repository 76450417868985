import React, {useState} from "react"
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";
import {Button} from "../../components/My/Button";
import Modal from "../../components/My/Modal";
import moment from "moment";
import {apiImportEvent} from "./Actions";
import Toast from "cogo-toast";

const TableData = ({data}) => {

    let [item, setItem] = useState(null)
    let [loading, setLoading] = useState(false)

    if (!data) return null

    const getBody = () => {
        if (!item) return null

        return (
            <div className={"w-full"}>
                <h5>Subjekt: {data?.subject?.name}</h5>
                <h5>Událost: {item.name}</h5>
                <h5>Datum: {moment(item.date).format("DD.MM.YYYY HH:mm:ss")}</h5>
            </div>
        )
    }

    const getState = (item) => {
        if (item.loading) return "Nahrávám";
        if (item.imported) {
            return moment(item.dateImport).format("DD.MM.YYYY HH:mm:ss")
        }

        return "-"
    }

    let columns = [
        {Header: 'ID', accessor: "id"},
        {Header: 'Název', accessor: "name"},
        {
            Header: 'Datum',
            accessor: "date",
            Cell: ({cell: {value}}) => <div>{value && moment(value).format("DD.MM.YYYY HH:mm:ss")}</div>,
        },
        {
            Header: 'Stav', accessor: d => getState(d)
        },
        {
            Header: 'Možnosti', accessor: d => (
                <Button text={"Importovat"} onClick={() => setItem(d)}/>
            )
        }
    ];


    const accept = () => {
        setLoading(true)
        apiImportEvent({subjectId: data.subject.id, eventId: item.id}, data => {
            Toast.success("Import dokončen.")
            setLoading(false)
            setItem(null)
        }, err => {


        })
    }


    return (
        <Widget>
            <Modal body={getBody()} title={"Nahrát událost"} visible={!!item}
                   close={() => setItem(null)}
                   footer={<>
                       <Button outline={true} text={"Zavřít"} onClick={() => setItem(null)}/>
                       <Button text={"Potvrdit"} onClick={() => accept()} loading={loading}/>
                   </>
                   }
            />
            <Datatable data={data.events} columns={columns}/>
        </Widget>
    )
}

export default TableData
