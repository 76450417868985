import {combineReducers} from 'redux'
import config from './config'
import leftSidebar from './left-sidebar'
import palettes from './palettes'
import navigation from './navigation'
import user from './user'

const rootReducer = combineReducers({
  navigation,
  config,
  leftSidebar,
  palettes,
  user
})

export default rootReducer
