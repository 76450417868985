import axios from "axios";
import Toast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { USER_RESET } from "../reducers/user";

export const instance = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "http://turnikets.local.cz" : "https://turnikets.enigoo.cloud",
});

instance.interceptors.response.use(undefined, function (error) {
    if (error === 401) {
        localStorage.removeItem("authorization-leg-enigoo");
        Toast.success("Došlo k odhlášení. Prosím přihlašte se znovu.");

        let dispatch = useDispatch();
        dispatch({ type: USER_RESET });
        let navigate = useNavigate();
        navigate("/login");
    }
    return Promise.reject(error);
});

export const onErrorFunction = (error) => {
    if(error?.response?.data){
        return error.response.data
    }else{
        return {type: null, message: null, data: null}
    }
}

