import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";

import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

var createStoreWithMiddleware = null;
if (process.env.NODE_ENV !== "production") {
    createStoreWithMiddleware = applyMiddleware(thunk, createLogger())(createStore);
} else {
    createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
}

//const middleware = applyMiddleware(promise(), thunk, createLogger());

const store = createStoreWithMiddleware(
    reducers // Loading done here
);

export default store;
