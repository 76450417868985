import React from "react";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import { FiArrowUp } from "react-icons/all";

const InputField = (props) => {


    const errorMessage = (message) => {
        return (
            <div className={"flex flew-row text-red-500"}>
                <FiArrowUp /> {message}
            </div>
        );
    };

    const renderError = (_props) => {
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return <div className={"text-red-500"}>Povinné pole</div>;
        }
        return null;
    };

    const _renderClassName = (meta) => {
        let className = `form-input `;
        if (props.instantRenderError) {
            if (meta.error) {
                className += "border-red-500 ";
            }
        }
        if (props.renderErrorWithoutSubmit) {
            if (meta.modified && meta.error) {
                className += "border-red-500 ";
            }
        } else if (meta.touched && meta.error) {
            className += "border-red-500 ";
        }

        return className;
    };

    return (
        <Field
            name={props.name}
            validate={(value) => {
                if (props.isMandatory) {
                    if (!value) return true;
                }
            }}
            render={(_props) => {
                return (
                    <div className={"form-element w-full p-1"}>
                        {props.label && (
                            <div className="form-label font-bold">
                                {props.label}
                                {props.isMandatory ? "*" : ""}
                            </div>
                        )}
                        <input
                            type={props.inputType}
                            className={_renderClassName(_props.meta)}
                            disabled={props.disabled}
                            value={_props.input.value}
                            onChange={(e) => {
                                _props.input.onChange(e.target.value);
                                if (props.onChange) {
                                    props.onChange(props.name, e.target.value);
                                }
                            }}
                            id={props.id}
                            onKeyDown={({ key }) => {
                                if (key === "Enter") {
                                    if (props.onEnter) props.onEnter();
                                }
                            }}
                        />
                        <div className={"text-sm pt-1"}> {renderError(_props)} </div>
                    </div>
                );
            }}
        />
    );
};

InputField.defaultProps = {
    inputType: "text",
    disabled: false,
    isMandatory: true
};

InputField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    inputStyle: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    showError: PropTypes.bool,
    isMandatory: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    dataTest: PropTypes.string,
    onEnter: PropTypes.func,
    id: PropTypes.string,
    inputType: PropTypes.oneOf(["text", "number", "password"]),
};

export default InputField;
