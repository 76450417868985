import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {FiMail, FiStar, FiUser, FiLogIn} from 'react-icons/fi'
import {apiLogout} from "../../pages/LoginPage/Actions";
import Toast from "cogo-toast";
import {useDispatch} from "react-redux";
import {USER_RESET} from "../../reducers/user";

const AccountLinks = () => {

    let dispatch = useDispatch();
    let navigate = useNavigate();

    const logout = () => {
        apiLogout((data) => {
            Toast.success("Byl jste úspěšně odhlášen.")
            dispatch({type: USER_RESET})
            localStorage.removeItem('authorization-leg-enigoo')
            navigate('/login')
        })
    }

    const items = [
        {
            url: null,
            icon: <FiLogIn size={18} className="stroke-current"/>,
            name: 'Odhlásit se',
            badge: null,
            onClick: logout
        }
    ]

    return (
        <div className="flex flex-col w-full">
            <ul className="list-none">
                {items.map((item, i) => (
                    <li key={i} className="dropdown-item">
                        <Link
                            to={!item.onClick ? item.url : () => {
                            }}
                            onClick={item.onClick ? item.onClick : () => {
                            }}
                            className="flex flex-row items-center justify-start h-10 w-full px-2">
                            {item.icon}
                            <span className="mx-2">{item.name}</span>
                            {item.badge && (
                                <span
                                    className={`uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 inline-flex items-center justify-center rounded-full ${item.badge.color} ml-auto`}>
                  {item.badge.number}
                </span>
                            )}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default AccountLinks
