import {Form} from "react-final-form";
import SubjectField from "../../FinalForm/api/SubjectField";
import {Button} from "../../components/My/Button";
import React from "react";
import Widget from "../../components/widget";


export const Filter = ({getEvent, loading, setData}) => {
    return (
        <Widget>
            <div className={"flex flex-col"}>
                <Form onSubmit={getEvent}
                      render={({handleSubmit}) => (
                          <form className="form flex flex-wrap w-full">
                              <div className="w-full">
                                  <SubjectField name={"subjectId"} label={"Vyberte subjekt"} validate={value => !value}
                                                onEnter={() => handleSubmit()} onChange={() => setData()}/>
                              </div>
                              <Button onClick={handleSubmit} text={"Potvrdit"} loading={loading}/>
                          </form>
                      )}
                />
            </div>
        </Widget>
    )
}
