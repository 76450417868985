import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Events from "./pages/Events/Events";
import Users from "./pages/Users/Users";
import Test from "./pages/Test/Test";
import Validation from "./pages/Validation/Validation";
import ActualStateVcb from "./pages/ArealVcb/ActualStateVcb";

const Routing = () => {

    return (
        <Routes>
            <Route path={"/dashboard"} element={<Dashboard/>}/>
            <Route path={"/events"} element={<Events/>}/>
            <Route path={"/users"} element={<Users/>}/>
            <Route path={"/validation"} element={<Validation/>}/>
            <Route path={"/test"} element={<Test/>}/>
            <Route path={"/vcb/actual"} element={<ActualStateVcb/>}/>
        </Routes>
    );
};
export default Routing;
