import React, {useEffect, useState} from "react"
import {apiGetDashboard} from "./Actions";
import Widget from "../../components/widget";
import moment from "moment";
import {Badge} from "../../components/badges";

const Dashboard = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)


    useEffect(() => {
        get()
    }, [])

    const get = () => {
        setLoading(true)

        apiGetDashboard((data) => {
            setData(data)
            setLoading(false)
        }, error => {
            setLoading(false)
        })
    }

    if (loading) return <div>Loader</div>
    if (!data) return null
    const body = () => data.map(item => {
        return (
            <Widget>
                    <div className={"w-full flex-col"}>
                        <div className={"flex"}><h5
                            className={"font-bold"}>{item.subjectName} {" ("}{item.ticketCount}/{item.ticketEntrance}{")"}
                            {item.loading && " NEDOKONČEN IMPORT!!!"}
                            </h5>
                        </div>
                    </div>
                    <div
                        className={"text-base"}>{item.name} - {moment(item.startTime).format("DD.MM.YYYY HH:mm:ss")}</div>
                    <div className={"w-full text-base"}>
                        Cron: {moment(item.cronFrom).format("DD.MM.YYYY HH:mm:ss")}
                        {" - "}
                        {moment(item.cronTo).format("DD.MM.YYYY HH:mm:ss")}
                    </div>
                    <div className={"w-full text-base"}>
                        Synchronizace: {item.lastSynchronization ? moment(item.lastSynchronization).format("DD.MM.YYYY HH:mm:ss") : "-"}
                    </div>
                    <div className={"w-full flex-row"}>
                        {item.jobs.map(i => <Badge rounded={true} color={"bg-orange-500"} children={<p>{i.name}</p>}/>)}
                    </div>
            </Widget>
        )
    })

    return (
        <div>
            {body()}
        </div>
    )

}

export default Dashboard
