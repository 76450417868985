import React, {useState} from "react"
import Modal from "../../components/My/Modal";
import {Form} from "react-final-form";
import SubjectField from "../../FinalForm/api/SubjectField";
import {Button} from "../../components/My/Button";
import InputField from "../../components/FinalForm/InputField";
import CheckboxField from "../../components/FinalForm/CheckboxField";
import {apiCreateUser, apiUpdateUser} from "./Actions";

const UserFormModal = ({visible, data, close}) => {

    let [loading, setLoading] = useState(false)

    let submit
    const save = (values) => {
        setLoading(true)
        if(!data) {
            apiCreateUser((values), (data) => {
                setLoading(false)
                close(true)
            }, error => {
                //USER_EXIST
                setLoading(false)
            })
        }else{
            apiUpdateUser((values), (data) => {
                setLoading(false)
                close(true)
            }, error => {
                //USER_EXIST
                setLoading(false)
            })
        }
    }

    const init = () => {
        if (!data) return {}
        return data
    }

    const getBody = () => {
        return (
            <Form onSubmit={save} initialValues={init()} render={({handleSubmit, values}) => {
                submit = handleSubmit
                return (
                    <div>
                        <SubjectField name={"subjects"} label={"Vyberte subjekt"} validate={value => !value}
                                      isMulti={true}
                                      onEnter={() => handleSubmit()}/>
                        <div className={"flex flex-wrap w-full"}>
                            <div className={"w-1/2"}>
                                <InputField label={"Login"} name={"login"} isMandatory={true}/>
                            </div>
                            <div className={"w-1/2"}>
                                <InputField label={"Jméno uživatele"} name={"name"} isMandatory={true}/>
                            </div>
                            {data &&
                                <div className={"w-1/2"}>
                                    <CheckboxField label={"Chci zadat nové heslo"} name={"isNewPassword"}/>
                                </div>
                            }
                            <div className={"w-full"}>
                                {(values.isNewPassword || !data) &&
                                    <InputField label={!data ? "Heslo" : "Nové heslo"} name={"password"}
                                                isMandatory={values.isNewPassword || !data}
                                                disabled={!values.isNewPassword && data}/>
                                }
                            </div>
                            <div className={"w-full"}>
                                <CheckboxField label={"Aktivní"} name={"active"}/>
                            </div>
                            <h4 className={"w-full"}>Práva</h4>
                            <div className={"w-1/3"}>
                                <CheckboxField label={"Nahrávání událostí"} name={"rights.admin.events.view"}/>
                            </div>
                            <div className={"w-1/3"}>
                                <CheckboxField label={"Validace vstupenky"} name={"rights.admin.validation.view"}/>
                            </div>
                            <div className={"w-1/3"}>
                                <CheckboxField label={"Uživatelé"} name={"rights.admin.users.view"}/>
                            </div>
                            <div className={"w-1/3"}>
                                <CheckboxField label={"Subjekty"} name={"rights.admin.subjects.view"}/>
                            </div>
                            <div className={"w-1/3"}>
                                <CheckboxField label={"Události aréna"} name={"rights.admin.arenaEvents.view"}/>
                            </div>
                            <div className={"w-1/3"}>
                                <CheckboxField label={"Průchody aréna"} name={"rights.admin.arenaEntrances.view"}/>
                            </div>
                            <div className={"w-1/3"}>
                                <CheckboxField label={"Výstaviště ČB"} name={"rights.admin.vcb.view"}/>
                            </div>
                        </div>
                    </div>
                )
            }}/>)

    }

    if (!visible) return null
    return (
        <>
            <Modal body={getBody()} title={!data ? "Vytvořit uživatele" : "Upravit uživatele"} visible={visible}
                   close={() => close(null)}
                   footer={<>
                       <Button outline={true} text={"Zavřít"} onClick={() => close()}/>
                       <Button text={"Potvrdit"} onClick={() => submit()} loading={loading}/>
                   </>
                   }
            />
        </>
    )

}

export default UserFormModal
