import React, { useEffect, useState } from "react"
import { apiAuthorizate } from "./Actions";
import { useDispatch } from "react-redux";
import { USER_FETCH_SUCCESS, USER_RESET } from "../../reducers/user";
import { useLocation, useNavigate } from "react-router-dom";
import {SET_MENU} from "../../reducers/navigation";


const Auth = () => {

    let dispatch = useDispatch()
    let [done, setDone] = useState(false);
    const location = useLocation();
    const navigation = useNavigate();

    const getRoute = (data) => {
        if (location.pathname === "/" || location.pathname === "/login") {
            navigation("/dashboard");
        }
    }

    useEffect(() => {
        authorizate()
    }, [])

    const authorizate = () => {
        apiAuthorizate(data => {
            dispatch({ type: USER_FETCH_SUCCESS, payload: data });
            dispatch({ type: SET_MENU, rights: data?.rights });
            setDone(true)
            getRoute(data)
        }, () => {
            localStorage.removeItem('authorization-turn-enigoo')
            dispatch({ type: USER_RESET })
            setDone(true)
        })
    }

    //return <Loader isDone={done} />

    return <div>Loader</div>
}
export default Auth
