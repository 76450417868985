import React, {useEffect, useState} from "react"
import QrReader from 'react-qr-scanner'
import Widget from "../../components/widget";
import {Form} from "react-final-form";
import SubjectField from "../../FinalForm/api/SubjectField";
import {Button} from "../../components/My/Button";
import InputField from "../../components/FinalForm/InputField";
import {apiGetValidateTicket} from "./Actions";


const Validation = () => {

    let [loading, setLoading] = useState(false)
    let [qrReader, setQrReader] = useState(false)


    const search = (values) => {
        setLoading(true)
        apiGetValidateTicket(values, (data) => {
            console.log(data)
            setLoading(false)
        }, error => {
            console.log(error)
            setLoading(false)
        })
    }

    const validate = (values) => {
        let error = {}
        if (!values.subjectId) error.subjectId = true;
        if (!values.ticketId && !values.bc) {
            error.ticketId = true;
            error.bc = true;
        }

        return error
    }

    return (
        <div className={"flex flex-wrap"}>
            <div className={"w-1/4"}>
                <Widget>
                    <Form onSubmit={search} validate={validate} render={({values, handleSubmit}) => {
                        return (
                            <div>
                                <SubjectField name={"subjectId"} label={"Subject"}/>
                                <InputField name={"ticketId"} label={"Číslo vstupenky"} isMandatory={false} inputType={"number"}/>
                                <InputField name={"bc"} label={"Barcode vstupenky"} isMandatory={false}/>

                                <Button text={"Vyhledat"} onClick={handleSubmit} loading={loading}/>
                            </div>
                        )
                    }}/>
                    {qrReader ?
                        <QrReader
                            delay={500}
                            style={{width: "100%"}}
                            onError={() => {
                            }}
                            onScan={(data) => {
                                if (data) console.log(data)
                            }}
                        /> :
                        <Button text={"Spustit čtení qr"} onClick={() => setQrReader(true)}/>
                    }
                </Widget>
            </div>

        </div>
    )

}

export default Validation
