import React, {useState, useEffect} from "react";
import {instance} from "../../config/axios-cnf";
import SelectField from "../SelectField";


const SubjectField = (props) => {

    let [subject, setSubject] = useState([]);
    let [loading, setLoading] = useState(true);
    let [isDisabled, setIsDisabled] = useState(false);
    let [id, setId] = useState(null);

    useEffect(() => apiGetSubject((data) => {
        let array = [];
        data.map(item => {
            array.push({
                label: item.name,
                value: item.id
            });
        })
        if(array.length === 1){
            //setIsDisabled(true)
            //setId(data[0]);
        }
        setSubject(array);
        setLoading(false)

    }, err => {
        //Toast.warn(translate("loading_error"))
    }), [])

    const getFormat = (value) => {
        return value
    }

    const getParse = (value) => {
        if (value === null) return null;
        return value;
    }

    return (
        <SelectField {...props} disabled={loading || isDisabled} options={subject} isMandatory={!!props.validate} isMulti={props.isMulti} />
    )


}

export const apiGetSubject = (callback, error) => {
    instance.get('/api/v2/user/subject')
        .then(response => callback(response.data))
}

export default SubjectField
