import {instance} from "../../config/axios-cnf";


export const apiGetUsers = (callback, error) => {
    instance.get("/api/v2/users")
        .then(response => callback(response.data))
        .catch(err => error(err))
}

export const apiCreateUser = (values, callback, error) => {
    instance.put("/api/v2/users", values)
        .then(response => callback(response.data))
        .catch(err => error(err))
}

export const apiUpdateUser = (values, callback, error) => {
    instance.post("/api/v2/users", values)
        .then(response => callback(response.data))
        .catch(err => error(err))
}
