import React, {useState} from "react"
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";
import {Button} from "../../components/My/Button";
import moment from "moment";
import Loader from "../../components/loader";

const TableData = ({data, setItem, loading}) => {


    if(loading) return <Loader/>
    if (!data) return null


    let columns = [
        {Header: 'ID', accessor: "id"},
        {Header: 'Login', accessor: "login"},
        {
            Header: 'Subjekty', accessor: "subjects",
            Cell: ({cell: {value}}) => <div>{value.length}</div>
        },
        {
            Header: 'Poslední akce', accessor: "lastAction",
            Cell: ({cell: {value}}) => <div>{value && moment(value).format("DD.MM.YYYY HH:mm:ss")}</div>
        },
        {
            Header: 'Možnosti', accessor: d => (
                <Button text={"Upravit"} onClick={() => setItem(d)}/>
            )
        }
    ];


    return (
        <Widget>
            <Datatable data={data} columns={columns}/>
        </Widget>
    )
}

export default TableData
