import React from "react"
import {Form} from "react-final-form";
import CheckboxField from "../../components/FinalForm/CheckboxField";
import InputField from "../../components/FinalForm/InputField";
import {Button} from "../../components/My/Button";


const Test = () => {
 return(
     <Form initialValues={{test:true}} onSubmit={()=>{alert("odeslano")}} render={({values, handleSubmit})=>{
         return(
             <div>
                 <h3>Test</h3>
                 <CheckboxField name={"test"} label={"label test"}/>
                 <InputField label={"test"} name={"test2"} isMandatory={true}/>
                 <pre>
                      <pre>{JSON.stringify(values, 0, 2)}</pre>
                 </pre>
                 <Button onClick={handleSubmit} text={"Odeslat"}/>
             </div>

         )
        }
     }/>
 )
}

export default Test;
