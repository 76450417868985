import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ClearCacheProvider } from 'react-clear-cache';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import * as serviceWorker from './serviceWorker'

ReactDOM.render(
    <ClearCacheProvider auto={true} duration={60000} basePath={process.env.NODE_ENV === "development" ? '/' :'/'}>
        <Router basename={process.env.NODE_ENV === "development" ? "/" : "/app/"}>
            <Routes>
                <Route path={'*'} element={<App />} />
            </Routes>
        </Router>
    </ClearCacheProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
