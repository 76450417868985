import React, {useEffect, useState} from "react"
import {apiGetActualStateVcb} from "./Actions";
import Widget from "../../components/widget";


const ActualStateVcb = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)

    useEffect(()=>{
        getData()
    },[])

    const getData = () => {
        setData(null)
        setLoading(true)
        apiGetActualStateVcb({date:"2022-01-01"}, data =>{
            setData(data)
            setLoading(false)
        }, error =>{
            setLoading(false)
            setData(null)
        })
    }

    const getBody = () => {
        if(loading) return "Načítám data"
        if(!data) return null
        if(data){
            return (
                <>
                    <div className={"w-full flex flex-row"}>
                        <div className={"flex w-1/2"}>
                        <Widget>
                            <h5>Načtených vstupenek</h5>
                            <h1>{data?.total?.in}</h1>
                        </Widget>
                        </div>
                        <div className={"flex w-1/2"}>
                        <Widget>
                            <h5>Načtených výstupů</h5>
                            <h1>{data?.total?.out}</h1>
                        </Widget>
                        </div>
                    </div>
                    <h5>Dle tarifů</h5>
                    <Widget>

                        <table className={"table table-striped"}>
                            <thead>
                                <th>Název</th>
                                <th>Počet</th>
                            </thead>
                            <tbody>
                            {data?.byTariff.map(item=>(
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.count}</td>
                                </tr>)
                            )}
                            </tbody>
                        </table>
                    </Widget>
                    <h5>Dle kategorií</h5>
                    <Widget>

                        <table className={"table table-striped"}>
                            <thead>
                                <th>Název kategorie</th>
                                <th>Název slevy</th>
                                <th>Počet</th>
                            </thead>
                            <tbody>
                            {data?.byCategory.map(item=>{
                                return item.map(it =>
                                (
                                <tr>
                                    <td>{it.categoryName}</td>
                                    <td>{it.discountName}</td>
                                    <td>{it.count}</td>
                                </tr>
                                ))
                                })
                            }
                            </tbody>
                        </table>
                    </Widget>
                    <h5>Dle typu objednávky</h5>
                    <Widget>

                        <table className={"table table-striped"}>
                            <thead>
                            <th>Typ objednávky</th>
                            <th>Počet</th>
                            </thead>
                            <tbody>
                            {data?.byTypeOrder.map(item=>(
                                <tr>
                                    <td>{item.id == 1 ? "Klasická objednávka" : "Partnerský program"}</td>
                                    <td>{item.count}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Widget>
                </>
            )
        }
    }

    return(
        <>
            <h3>Aktuální stav průchody</h3>
            {getBody()}
        </>
    )

}

export default ActualStateVcb
